import vue from 'vue';
import {store} from '@/store';
import _ from "lodash";

let modifiers = [];

export default {
    async install(Vue) {
        const maxPrimerLength = 200;
        let bhqForReplaceIfInner = null;
        let aliases = null;
        let modifierNamesSorted = null;
        let rx = null;
        let rxForPrimerParse = null;
        let checkErrorModifiers = null;
        let rxCheckErrorModifiers = null;

        const reloadModifiers = async function() {

            modifiers = await vue.prototype.$myHttp.post('/api/modifiers/findModifiers', {});
            if (!modifiers) {
              return;
            }
            store.commit('modifiers/setItems', {count: modifiers.length, items: modifiers});
            // Если при экспорте вставляют bhq то надо будет заменять на dt-bhq
            bhqForReplaceIfInner = [
                { from: 10, to: _.find(modifiers, mod => mod.id === 15) },
                { from: 11, to: _.find(modifiers, mod => mod.id === 16) }
            ];
            aliases = modifiers.reduce((acc, mod) => {
                mod.aliases && acc.push(...mod.aliases.split(',').map(item => item.replace(/-/g, '').toUpperCase()));
                return acc;
            }, []);

            modifierNamesSorted = aliases.sort(function(a, b) {
                return a.length > b.length ? -1 : 1;
            });

            rx = modifierNamesSorted.reduce(function(acc, item) {
                acc += acc === '' ? '' : '|';
                acc += '(' + item.replace('+','\\+') + ')|(-\\(' + item.replace('+','\\+') + '\\)-)|(\\(' + item.replace('+','\\+') + '\\))';
                return acc;
            }, '');
            rxForPrimerParse = new RegExp(rx, 'gi');
            checkErrorModifiers =
                `([(\\[/-]([^)\\/]+(${rx}))[)\\]/-])|` +
                `([(\\[/-]((${rx})[^)\\/]+)[)\\]/-])|` +
                `([(\\[/-]([^)\\/]+(${rx})[^)\\/]+)[)\\]/-])`;
            rxCheckErrorModifiers = new RegExp(checkErrorModifiers, 'ig');
            return modifiers;
        }
        const letters = 'ACGTIU*';




        // Если эти праймеры на 5` очистка не обязательна
        const modifiersWoClean = [12, 13, 14];

        const degenArraySorted = [['ACGT', 'N'], ['AGT', 'D'], ['ACT', 'H'], ['ACG', 'V'], ['AC', 'M'], ['AG', 'R'], ['AT', 'W'],
            ['CG', 'S'], ['CT', 'Y'], ['GT', 'K'], ['TGC', 'B']].map(function(item) {
            return [item[ 0 ].split('').sort().join(''), item[ 1 ]];
        });

        const acceptedLetters = letters +  degenArraySorted.map(a => a[1]);

        let replaceInnerModifiersIfNeed = function(primer) {
            const primerRightPosition = getPrimerLength(primer) - 1;
            const innerMods = _.filter(primer.primerModifierRls, mod =>
                mod.position > 0 &&
                mod.position < primerRightPosition &&
                _.findIndex(bhqForReplaceIfInner, item => item.from === mod.modifierFk) !== -1
            );
            innerMods.forEach(mod => {
                const idx = primer.primerModifierRls.indexOf(mod);
                primer.primerModifierRls[ idx ].modifier = _.find(bhqForReplaceIfInner, item => item.from === mod.modifierFk).to;
            });
        };

        let getPrimerLength = function(primer) {
            return (primer.sequence?.length || 0) + (primer.primerModifierRls?.length || 0 );
        };

        let parsePrimerString = function(src) {
            let savePtr = 0;
            let fnd;
            const resultPrimer = {
                sequence: '',
                primerModifierRls: [],
                price: 0,
                discountPrice: 0
            };

            while ((fnd = rxForPrimerParse.exec(src))) {
                if (savePtr < fnd.index) {
                    resultPrimer.sequence += src.substring(savePtr, fnd.index);
                }
                const fndModifier = modifiers.find(m => m.aliases.split(",").some(a => a.toLowerCase().replace(/-/g, '') === fnd[ 0 ].toLowerCase()));

                if (fndModifier) {
                    addModifier(resultPrimer, fndModifier);
                }
                savePtr = fnd.index + fnd[ 0 ].length;
            }
            if (savePtr < src.length) {
                resultPrimer.sequence += src.substring(savePtr);
            }
            return resultPrimer;
        };

        let addModifier = function(primer, modifier) {
            primer.primerModifierRls.push(
                {
                    position: getPrimerLength(primer),
                    modifierFk: modifier.id,
                    modifier: modifier
                });
            return primer;
        };

        // eslint-disable-next-line no-useless-escape
        const ruChars = 'А|С|Т|М|Н|К|В|Х|\[|\{|\]|\}|<|>|\!|\,';
        const ruCharsClean = ruChars.replace(/\|/g, '');
        // eslint-disable-next-line no-useless-escape
        const enChars = 'A|C|T|M|H|K|B|X|\(|\(|\)|\)|(|)';
        const enCharsClean = enChars.replace(/\|/g, '');

        let replaceRuAndBadCharsInString = function(src) {
            let result = src.replace(/\s/gi, '');
            result = result.replace(new RegExp(ruChars, 'gi'), function(char) {
                const idx = ruChars.indexOf(char.toUpperCase());
                return idx >= enChars.length ? '' : enChars[ ruChars.indexOf(char.toUpperCase()) ];
            });
            return result.replace(/\(+/g, '(').replace(/\)+/g, ')');
        };

        let translitRuChar = function(char) {
            let idx = ruCharsClean.indexOf(char);
            if (idx === -1) return char;
            return idx >= enCharsClean.length ? '' : enCharsClean[idx];
        };



        let checkErrorModifiersAndThrowEx = function(src) {
            let fnd;
            rxCheckErrorModifiers.lastIndex = 0;
            while ((fnd = rxCheckErrorModifiers.exec(src.replace(/[ -]/g, '')))) {
                const fndValue = (fnd[ 1 ] || fnd[ 0 ]).replace(/[)(/]/g, '');
                if (modifierNamesSorted.indexOf(fndValue.toUpperCase()) === -1) {
                    throw  new Error('Недопустимый модификатор: \'' + fndValue + '\'');
                }
            }
            return '';
        };

        let replaceDegenerateCharsInString = function(src) {
            src = src.replace(/-/g, '--');                                                                               // что бы регэкс спрвился с ситуацией mod-mod
            src = src.replace(/(-([A-Z]+)-)|(\(([A-Z]+)\))/g, function(match) {
                const result = findDegenerateChar(match);
                return result || match;
            });
            return src.replace(/-/g, '');
        };

        let findDegenerateChar = function(srcChars) {
            srcChars = srcChars.replace(/[)(/\\-]/g, '');                                                             // В srcChars могут быть скобки или дефисы
            const fndChars = srcChars.split('').sort().join('');                                                        // Сортируем побуквено что бы найти совпадение в degenArraySorted
            const fndEl = _.find(degenArraySorted, item => item[ 0 ] === fndChars);
            // Если есть совпадени то возвращаем букву
            return fndEl ? fndEl[ 1 ] : null;                                                                          // иначе - то что было
        };

        let getModPositionName = function(primer, modifier) {
            return modifier.position === 0
                ? 'Left'
                : (modifier.position === getPrimerLength(primer) - 1
                    ? 'Right'
                    : 'In');
        };

        let checkModifierError = function(primer, modifierRl) {
            let modPosName = getModPositionName(primer, modifierRl);
            debugger;
            let ptr = primer.primerModifierRls.filter(rl => rl.position < modifierRl.position).length;
            if (primer.sequence[modifierRl.position - ptr] === '*') {
                return true;
            }

            // Проверяем что модификатор стоит где можно
            if (!modifierRl.modifier[ `acceptedIn${modPosName}` ]) {
                return true;
            }

            // Проверяем что две краски не стоят рядом
            return modifierRl.modifier?.type === 'fluorophore'
                && primer.primerModifierRls.some(function(item) {
                    return item.modifier?.type === 'fluorophore'
                        && Math.abs(item.position - modifierRl.position) === 1;
                });
        };

        let parse = function(src, withException) {
            src = replaceRuAndBadCharsInString(src);
            withException && checkErrorModifiersAndThrowEx(src);
            src = src.toUpperCase();
            src = replaceDegenerateCharsInString(src);
            src = src.replace(/[)(\\/ ]/g, '');                                                                          // Дефисы и скобки нас больше не интересуют
            const resultPrimer = parsePrimerString(src);
            replaceInnerModifiersIfNeed(resultPrimer);

            return resultPrimer;
        };

        let getErrorMessages = function(primer, needModifier) {
            let result = [];
            const primerLength = getPrimerLength(primer);
            if (primerLength === 0) {
                result.push({ code: 'empty', message: 'Пустой праймер' });
            }
            if (primerLength > maxPrimerLength) {
                result.push({ code: 'maxLenght', message: `Максимальная длина праймера ${maxPrimerLength}н` })
            }
            let rx = new RegExp(`[^${acceptedLetters}]`,'gi');
            if (rx.test(primer.sequence)) {
                result.push({ code: 'char', message: 'Недопустимые символы в праймере' });
            }

            if (primer.primerModifierRls?.some(i => checkModifierError(primer, i))) {
                result.push({ code: 'modifier', message: 'Ошибочная позиция модификатора' });
            }
            if (primer.scale === '') {
                result.push({ code: 'scale', message: 'Не задана шкала' });
            }
            if ( needModifier.toLowerCase() === 'yes' &&
                 (primer.primerModifierRls?.length || 0) === 0 &&
                  !primer.sequence.includes('*'))
            {
                result.push({code: 'modifierNeed', message: 'В праймере должен быть модификатор или *'});
            }
            if ( needModifier.toLowerCase() === 'no' &&
                (((primer.primerModifierRls?.length || 0) > 0) || primer.sequence.includes('*') )) {
                result.push({code: 'modifierNeed', message: 'В праймере не должны быть модификаторы и *'});
            }
            if (primer.sequence[0] === '*') {
                result.push({ code: 'modifier', message: 'Ошибочная позиция *' });
            }
            if (primer.sequence.includes('**')) {
                result.push({ code: 'modifier', message: 'Недопустимая комбинация **' });
            }
            return result;
        };

        let isForceClean = function(primer) {
            if (!primer.primerModifierRls?.length) {
                return false;
            }
            if (primer.primerModifierRls.length === 1) {
                const modLink = primer.primerModifierRls[ 0 ];
                return !((modifiersWoClean.indexOf(modLink.modifierFk) >= 0) &&
                    (getModPositionName(primer, modLink) === 'Left'));
            }
            return true;
        };

        let buildSequenceChars = function(src) {
            return [].map.call(src,
                               char => ({
                                   type: 'char',
                                   text: char,
                                   error: !acceptedLetters.includes(char)
                               }));
        }

        let buildEditView = function(primer) {
            let savePtr = 0;
            let modCount = 0;

            let sortedMods = _.sortBy(primer.primerModifierRls || [], function(mod) {
                return mod.position;
            });
            return sortedMods.length === 0
                ? buildSequenceChars(primer.sequence || '')
                : sortedMods.reduce((acc, modRl, index, array) => {
                    if (savePtr < modRl.position) {
                        acc.push(...buildSequenceChars(primer.sequence.substring(savePtr - modCount, modRl.position - modCount)))
                    }
                    if (modRl.modifier) {
                        acc.push({
                                     type: 'modifier',
                                     text: modRl.modifier.name,
                                     saveModRl: modRl,
                                     error: checkModifierError(primer, modRl)
                                 });
                    }
                    savePtr = modRl.position + 1;
                    modCount++;
                    if (index === array.length - 1 && savePtr <= primer.sequence.length + modCount) {
                        acc.push(...buildSequenceChars(primer.sequence.substring(savePtr - modCount)));
                    }
                    return acc;
                }, []);
        }

        let parseEditView = function(src) {
            let result = {
                sequence: '',
                primerModifierRls: []
            }
            src.forEach(el => {
                if (el.type === 'char') {
                    result.sequence += el.text.toUpperCase();
                    if (el.text === ')') {
                        result.sequence = replaceDegenerateCharsInString(result.sequence);
                    }
                } else {
                    if (el.saveModRl) {
                        result.primerModifierRls.push(Object.assign(el.saveModRl, { position: result.sequence.length + result.primerModifierRls.length }))
                    } else {
                        result.primerModifierRls.push(
                            {
                                modifierFk: el.modifierId,
                                modifier: modifiers.find(m => m.id === el.modifierId),
                                position: getPrimerLength(result)
                            });
                    }
                }
            });
            return result;
        }

        let buildHtml = function(src, cursorPosition) {
            let tripleCounter = 0;
            return src.length === 0
                ? `<div class="element-div ${cursorPosition !== null && cursorPosition !== undefined ? 'element-div-current':''}" ></div>`
                :src.reduce((acc, element, idx) => {
                    let isStartTriple = tripleCounter % 3 === 0 && tripleCounter > 0;
                    tripleCounter++;
                    let elementClasses = [
                        'element-div',
                        element.type === 'modifier' ? 'element-div-modifier' : 'element-div-char',
                        idx === cursorPosition ? 'element-div-current' : '',
                        element.error ? 'element-div-error' : '',
                        isStartTriple ? 'element-div-firstInTpl' : '',
                        idx !== 0 ? ''
                            : (this.cursorPosition === -1 ? 'first-element-current' : 'first-element')
                    ].filter(e => !!e).join(' ');
                    let txt = element.type === 'modifier' ? `/${element.text}/` : element.text;
                    return acc + `<div class='${elementClasses}'><div>${txt}</div></div>`
                }, '');
        };

        let buildText = function(src, withHtml = false) {
            let tripleCounter = 0;
            return src.length === 0
                ? ''
                :src.reduce((acc, element) => {
                    let isStartTriple = tripleCounter % 3 === 0 && tripleCounter > 0;
                    tripleCounter++;
                    let txt = element.type !== 'modifier'
                        ? element.text
                        : withHtml ? `<span style='background-color:#D7F5FF; color:#0676DE;white-space: nowrap;'>/${element.text}/</span>` : `/${element.text}/`
                    return acc + (isStartTriple ? '  ' : '') + txt;
                }, '');
        };

        let deleteModifierByPositionName = function(primer, position) {
            let array = buildEditView(primer);
            let idx = 0;
            if (position !== 'left') {
              idx = array.length - 1;
            }
            if (idx>= 0 && array[idx].type === 'modifier') {
                array.splice(idx, 1);
            }
            let newPrimer = parseEditView(array);
            primer.sequence = newPrimer.sequence;
            primer.primerModifierRls = newPrimer.primerModifierRls;
        };

        let insertModifier = function(primer, modifier, positionName) {
            let view = buildEditView(primer);
            let idx =  positionName === 'left'
                ? 0
                : view.length -1;
            if (view[idx].type === 'modifier') return;
            let modEl = {
                type: 'modifier',
                text: modifier.name,
                modifierId: modifier.id
            }
            if (positionName === 'left') {
                view.splice(idx ,0,modEl);
            } else {
                view.push(modEl);
            }

            let newPrm = parseEditView(view);
            primer.sequence = newPrm.sequence;
            primer.primerModifierRls = newPrm.primerModifierRls;
        };

        let getSortedModsForPosition = function(position) {
            const filtered = modifiers.filter(function(item) {
                let propName = 'acceptedIn' + position.charAt(0).toUpperCase() + position.slice(1)
                return item[propName];
            });
            return _.sortBy(filtered, ['name']);
        };

        let primersIsEqual = function(primer1, primer2) {
            return buildText(buildEditView(primer1)) === buildText(buildEditView(primer2))
        }

        let getModifiers = () => modifiers;

        Vue.prototype.$primerHelper = {
            getModifiers,
            parse,
            buildEditView,
            parseEditView,
            getErrorMessages,
            getPrimerLength,
            isForceClean,
            translitRuChar,
            replaceRuAndBadCharsInString,
            buildHtml,
            deleteModifierByPositionName,
            maxPrimerLength,
            insertModifier,
            getSortedModsForPosition,
            buildText,
            reloadModifiers,
            primersIsEqual
        }
    }
}
